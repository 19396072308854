import { useEffect, useState } from 'react';
import { Form, Button, Col, Image, Alert } from 'react-bootstrap';
import Resizer from 'react-image-file-resizer';
import { getPoints, getTrips } from '../../api/tripsApi';
import { savePoint } from '../../api/tripsApi';
import { directions } from '../utils/tools';
import classes from './EditTrips.module.css';

const resetFormData = {
  pointNumber: '',
  pointName: '',
  longitude: '',
  latitude: '',
  distanceNoticalMiles: '',
  date: '',
  windDirection: '',
  windStrength: '',
  freeText: '',
  paddleDirection: '',
  image: null,
  password: '',
};
const EditTripsForm = () => {
  const [tripOptions, setTripOptions] = useState([]);
  const [tripPoints, setTripPoints] = useState([]);
  const [pointId, setPointId] = useState(null);
  const [error, setError] = useState('');
  const [saving, setSaving] = useState(false);
  const [currentPosition, setCurrentPosition] = useState({
    longitude: null,
    latitude: null,
  });
  const [formData, setFormData] = useState({
    tripId: '',
    point: '',
    ...resetFormData,
  });
  const [imageBase64, setImageBase64] = useState(null);

  const convertHTMLEntitiesToChars = (text) => {
    const parser = new DOMParser();
    const decodedString = parser.parseFromString(
      `<!doctype html><body>${text}`,
      'text/html'
    ).body.textContent;
    return decodedString;
  };

  useEffect(() => {
    const fetchTrips = async () => {
      const data = await getTrips();
      setTripOptions(data);
    };

    fetchTrips();
    navigator.geolocation.getCurrentPosition((position) => {
      setCurrentPosition({
        longitude: position.coords.longitude,
        latitude: position.coords.latitude,
      });
    });
  }, []);

  const calculateNextPointNumber = (points) => {
    if (points.length === 0) {
      return 0;
    }

    const maxPointNumber = points.reduce((max, item) => {
      return item.pointNumber > max ? item.pointNumber : max;
    }, 0);

    // Add 1 to the maximum pointNumber to get the next pointNumber
    const nextPointNumber = maxPointNumber + 1;

    return nextPointNumber;
  };

  const onTripSelect = async (e) => {
    setError('');
    setPointId(null);
    if (!e.target.value) {
      setTripPoints([]);
      return;
    }
    const data = await getPoints({ tripId: e.target.value });
    setTripPoints(data);
    const nextPointNumber = calculateNextPointNumber(data);
    setFormData((prevFormData) => ({
      ...prevFormData,
      point: '',
      pointNumber: nextPointNumber,
      longitude: currentPosition.longitude || '',
      latitude: currentPosition.latitude || '',
      distanceNoticalMiles: '',
      date: '',
      windDirection: '',
      windStrength: '',
      freeText: '',
      paddleDirection: '',
      pointName: '',
      image: null,
      password: '',
    }));
  };

  const onPointSelect = async (e) => {
    const { name, value } = e.target;
    if (!value) {
      const tripId = formData.tripId;
      setFormData({
        ...resetFormData,
        tripId: tripId,
        longitude: currentPosition.longitude || '',
        latitude: currentPosition.latitude || '',
      });
      setPointId(null);
      return;
    }
    if (!tripPoints) {
      Alert.alert('No points found for this trip.');
      return;
    }
    const point = tripPoints.find((point) => point._id === value);
    setPointId(point._id);
    setFormData((prevFormData) => ({
      [name]: value,
      tripId: prevFormData.tripId,
      pointNumber: point?.pointNumber || calculateNextPointNumber(tripPoints),
      longitude: point?.longitude || '',
      latitude: point?.latitude || '',
      distanceNoticalMiles: point?.distanceNoticalMiles || '',
      date: point?.date,
      windDirection: point?.windDirection || '',
      windStrength: point?.windStrength || '',
      freeText: point?.freeText || '',
      paddleDirection: point?.paddleDirection || '',
      pointName: point?.pointName || '',
      image: point?.image || null,
      password: '',
    }));
  };

  const onDirectionSelect = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const resizeFile = (file) => {
    const uri = new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        600,
        600,
        'WEBP',
        80,
        0,
        (uri) => {
          setImageBase64(uri);
          resolve(uri);
        },
        'base64'
      );
    });
    return uri;
  };
  const handleImageChange = async (e) => {
    try {
      const file = e.target.files[0];
      const image = await resizeFile(file);
      setFormData((prevFormData) => ({ ...prevFormData, image }));
    } catch (err) {
      console.error(err);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'tripId') {
      onTripSelect(e);
    }
    if (name === 'points') {
      return onPointSelect(e);
    }

    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      setSaving(true);
      const success = await savePoint(formData, pointId);
      if (success) {
        setFormData({ ...resetFormData, tripId: formData.tripId });
        setPointId(null);
        setTripPoints([]);
        setImageBase64(null);
      } else {
        setError("Couldn't save the point.");
      }
      setSaving(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Col>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="tripId">
          <Form.Label className={classes.Label}>Trip</Form.Label>
          <Form.Control
            size="lg"
            as="select"
            name="tripId"
            value={formData.tripId}
            onChange={handleChange}
            required
          >
            <option value="" key="99999">
              Select a Trip
            </option>
            {tripOptions &&
              tripOptions.map((trip) => (
                <option key={trip.tripId} value={trip.tripId}>
                  {trip.tripName}
                </option>
              ))}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="pointId">
          <Form.Label className={classes.Label}>Point</Form.Label>
          <Form.Control
            size="lg"
            as="select"
            name="points"
            value={formData.point}
            onChange={handleChange}
            // required
          >
            <option value="" key="999999">
              New Point / Select a Point
            </option>
            {tripPoints &&
              tripPoints.map((point) => (
                <option key={point._id} value={point._id}>
                  {convertHTMLEntitiesToChars(
                    `${point.pointNumber} - ${point.pointName}`
                  )}
                </option>
              ))}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="pointNumber">
          <Form.Label className={classes.Label}>Point Number</Form.Label>
          <Form.Control
            size="lg"
            type="number"
            name="pointNumber"
            value={formData.pointNumber}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="pointName">
          <Form.Label className={classes.Label}>Point Name</Form.Label>
          <Form.Control
            size="lg"
            type="text"
            name="pointName"
            value={convertHTMLEntitiesToChars(formData.pointName)}
            onChange={handleChange}
            autoComplete="off"
            required
          />
        </Form.Group>

        <Form.Group controlId="longitude">
          <Form.Label className={classes.Label}>Longitude</Form.Label>
          <Form.Control
            size="lg"
            type="number"
            name="longitude"
            value={formData.longitude}
            onChange={handleChange}
            // required
          />
        </Form.Group>

        <Form.Group controlId="latitude">
          <Form.Label className={classes.Label}>Latitude</Form.Label>
          <Form.Control
            size="lg"
            type="number"
            name="latitude"
            value={formData.latitude}
            onChange={handleChange}
            // required
          />
        </Form.Group>

        <Form.Group controlId="distanceNoticalMiles">
          <Form.Label className={classes.Label}>
            Distance (Nautical Miles)
          </Form.Label>
          <Form.Control
            size="lg"
            type="number"
            name="distanceNoticalMiles"
            value={formData.distanceNoticalMiles}
            onChange={handleChange}
            // required
          />
        </Form.Group>

        <Form.Group controlId="paddleDirection">
          <Form.Label className={classes.Label}>Paddle Direction</Form.Label>
          <Form.Control
            as="select"
            value={formData.paddleDirection}
            onChange={onDirectionSelect}
            name="paddleDirection"
          >
            <option value="">Paddle Direction</option>
            {Object.entries(directions).map(([key, value]) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="date">
          <Form.Label className={classes.Label}>Date</Form.Label>
          <Form.Control
            size="lg"
            type="date"
            name="date"
            value={formData.date.split('T')[0]}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="windDirection">
          <Form.Label className={classes.Label}>Wind Direction</Form.Label>
          <Form.Control
            as="select"
            value={formData.windDirection}
            onChange={onDirectionSelect}
            name="windDirection"
          >
            <option value="">Wind Direction</option>
            {Object.entries(directions).map(([key, value]) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="windStrength">
          <Form.Label className={classes.Label}>Wind Strength</Form.Label>
          <Form.Control
            size="lg"
            type="number"
            name="windStrength"
            value={formData.windStrength}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="freeText">
          <Form.Label className={classes.Label}>Free Text</Form.Label>
          <Form.Control
            size="lg"
            as="textarea"
            rows={8}
            name="freeText"
            value={convertHTMLEntitiesToChars(formData.freeText)}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <hr />
        <Form.Group controlId="image">
          <Form.Label className={classes.Label}>Image</Form.Label>
          <Form.Control
            type="file"
            name="image"
            label="Choose an image"
            onChange={handleImageChange}
            accept="image/*"
          />
        </Form.Group>
        <hr />
        {error && <div className={classes.Error}>{error}</div>}
        <Button
          variant="primary"
          type="submit"
          style={{
            marginTop: '20px',
            marginBottom: '100px',
            width: '100%',
          }}
          disabled={saving}
        >
          Submit
        </Button>
      </Form>
      <hr />
      <Image src={imageBase64} style={{ width: '100%' }} />
      <hr />
    </Col>
  );
};

export default EditTripsForm;
