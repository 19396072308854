import Col from 'react-bootstrap/Col';

const PageNotFound = () => {
  return (
    <Col>
      <h2>404 Error</h2>
      <h2>Page Not Found</h2>
    </Col>
  );
};

export default PageNotFound;
