import axios from 'axios';

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL_GCP}`,
  // headers: { 'Content-Type': 'application/json' },
});

// api.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     return Promise.reject(error);
//   }
// );

// api.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     console.error('Axios Error:', error);
//     if (error.response) {
//       console.error('Response Data:', error.response.data);
//       console.error('Response Status:', error.response.status);
//       console.error('Response Headers:', error.response.headers);
//     } else if (error.request) {
//       console.error('Request:', error.request);
//     } else {
//       console.error('Error Message:', error.message);
//     }
//     return Promise.reject(error);
//   }
// );
