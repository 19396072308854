// src/contexts/UserContext.js
import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useGoogleLogin } from '@react-oauth/google';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      localStorage.setItem('user', JSON.stringify(codeResponse));
      setUser(codeResponse);
      setError(null);
    },
    onError: (error) => {
      console.error('Login Failed:', error);
      setError('Failed to log in with Google. Please try again.');
    },
  });

  const logout = useCallback(() => {
    localStorage.removeItem('user');
    setUser(null);
  }, []);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
    setLoading(false);
  }, []);

  return (
    <UserContext.Provider value={{ user, login, logout, loading, error }}>
      {children}
    </UserContext.Provider>
  );
};
