/**
 * Custom hook for initializing Google Analytics.
 */
import { useEffect } from 'react';

export const useGoogleAnalytics = () => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', process.env.REACT_APP_GA_TRACKING_ID);
  }, []);
};
