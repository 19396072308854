import { api } from './Api';
import { handleError } from './apiUtils';
import moment from 'moment';

const opt = {
  headers: { 'Content-Type': 'application/json' },
  next: {
    revalidate: 3600,
  },
};

export const getForecast = async (location) => {
  try {
    const response = await api.get(`/forecast?point=${location}`, opt);
    if (undefined === response) return false;
    if (response.error) throw response.error;
    // const { localTime, labels } = getLocalTimes(response.data.marineForecast);
    const { localTime, dateLabel, timeLable, dayOnly } = getLocalTimes(
      response.data.marineForecast
    );
    response.data.marineForecast.hourly.localTime = localTime;
    response.data.marineForecast.hourly.dateLabel = dateLabel;
    response.data.marineForecast.hourly.timeLable = timeLable;
    response.data.marineForecast.hourly.dayOnly = dayOnly;
    const oneDayForecast = getForecastSubset(response.data.marineForecast);

    return {
      oneDayForecast,
      weekForecast: response.data.marineForecast.hourly,
    };
  } catch (error) {
    handleError(error);
  }
};

const getLocalTimes = (data) => {
  const { time } = data.hourly;
  const localTime = [];
  const dateLabel = [];
  const timeLable = [];
  const dayOnly = [];

  for (let i = 0; i < time.length; i++) {
    const timeElemtent = getDateLabel(time[i]);
    localTime.push(timeElemtent.date);
    dateLabel.push(timeElemtent.dateLabel);
    timeLable.push(timeElemtent.timeLable);
    dayOnly.push(timeElemtent.dayOnly);
  }

  return { localTime, dateLabel, timeLable, dayOnly };
};

const getDateLabel = (dateString) => {
  const [datePart, timePart] = dateString.split('T');
  const [year, month, day] = datePart.split('-').map(Number);
  const [hour] = timePart.split(':').map(Number);
  const date = new Date(Date.UTC(year, month - 1, day, hour, 0, 0));
  const momentDate = moment(date);
  // const formattedDate = momentDate.format(
  //   hour >= 0 && hour <= 4 ? 'DD/MM HH:mm' : 'HH:mm'
  // );
  const formattedDate = momentDate.format('DD/MM HH:mm');
  const formattedHour = momentDate.format('HH:mm');

  return {
    date: date,
    dateLabel: formattedDate,
    timeLable: formattedHour,
    dayOnly: momentDate.format('DD/MM'),
  };
};

const getForecastSubset = (data) => {
  const {
    localTime,
    dateLabel,
    timeLable,
    dayOnly,
    wave_height,
    wave_direction,
    wave_period,
    wind_wave_height,
    wind_wave_period,
  } = data.hourly;
  const now = moment();
  const twentyFourHoursFromNow = moment().add(24, 'hours');

  let startIndex = null;
  let endIndex = null;

  for (let i = 0; i < localTime.length; i++) {
    const date = moment(localTime[i]);
    if (date.isSameOrAfter(now) && startIndex === null) {
      startIndex = i;
    }
    if (date.isSameOrAfter(twentyFourHoursFromNow)) {
      endIndex = i;
      break;
    }
  }

  const oneDayLocalTime = localTime.slice(startIndex, endIndex);
  const oneDayDateLabels = dateLabel.slice(startIndex, endIndex);
  const oneDayTimeLables = timeLable.slice(startIndex, endIndex);
  const oneDayDayOnly = dayOnly.slice(startIndex, endIndex);
  const oneDayWaveHeight = wave_height.slice(startIndex, endIndex);
  const oneDayWaveDirection = wave_direction.slice(startIndex, endIndex);
  const oneDayWavePeriod = wave_period.slice(startIndex, endIndex);
  const oneDayWindWaveHeight = wind_wave_height.slice(startIndex, endIndex);
  const oneDayWindWavePeriod = wind_wave_period.slice(startIndex, endIndex);

  return {
    oneDayLocalTime,
    oneDayDateLabels,
    oneDayTimeLables,
    oneDayDayOnly,
    oneDayWaveHeight,
    oneDayWaveDirection,
    oneDayWavePeriod,
    oneDayWindWaveHeight,
    oneDayWindWavePeriod,
  };
};

// const orgaizeWeatherForecast = (data) => {};
