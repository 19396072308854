import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const breakLines = (txt) => {
  const newText = txt?.replaceAll('\r\n', '<br />');
  return newText?.replaceAll('\n', '<br />');
};

export const htmlParser = (txt) => {
  const t = breakLines(txt);
  const parser = new DOMParser();
  return parser.parseFromString(t, 'text/html').body.textContent;
};

export const degToDms = (dd, isLng) => {
  var dir = dd < 0 ? (isLng ? 'W' : 'S') : isLng ? 'E' : 'N';

  var absDd = Math.abs(dd);
  var deg = absDd | 0;
  var frac = absDd - deg;
  var min = (frac * 60) | 0;
  var sec = frac * 3600 - min * 60;
  // Round it to 2 decimal points.
  sec = Math.round(sec * 100) / 100;
  // return deg + '°' + min + "'" + sec + '"' + dir;
  return `${deg}° ${min}' ${sec}" ${dir}`;
};

export const getDirection = (dir) => {
  switch (dir) {
    case 'N':
      return '&uarr;';
    case 'S':
      return '&darr;';
    case 'W':
      return '&larr;';
    case 'E':
      return '&rarr;';
    case 'NW':
      return '&nwarr;';
    case 'NE':
      return '&nearr;';
    case 'SW':
      return '&swarr;';
    case 'SE':
      return '&searr;';
    default:
      return '';
  }
};

export const getWindDirection = (dir) => {
  switch (dir) {
    case 'S':
      return '&uarr;';
    case 'N':
      return '&darr;';
    case 'E':
      return '&larr;';
    case 'W':
      return '&rarr;';
    case 'SE':
      return '&nwarr;';
    case 'SW':
      return '&nearr;';
    case 'NE':
      return '&swarr;';
    case 'NW':
      return '&searr;';
    default:
      return '';
  }
};

export const getDegreesDirection = (dir) => {
  switch (dir) {
    case 'N':
      return 0;
    case 'S':
      return 180;
    case 'W':
      return 270;
    case 'E':
      return 90;
    case 'NW':
      return 315;
    case 'NE':
      return 45;
    case 'SW':
      return 225;
    case 'SE':
      return 135;
    default:
      return '';
  }
};

export const getWindDegreesDirection = (dir) => {
  switch (dir) {
    case 'S':
      return 0;
    case 'N':
      return 180;
    case 'E':
      return 270;
    case 'W':
      return 90;
    case 'SE':
      return 315;
    case 'SW':
      return 45;
    case 'NE':
      return 225;
    case 'NW':
      return 135;
    default:
      return '';
  }
};

export const colors = {
  primary: '#1F2833',
  secondary: '#C5C6C7',
  link: '#45A29E',

  a: '#688d93',
  b: '#C3C3C1',
  c: '#B6B8BB',
  d: '#72706A',
  e: '#838876',
  f: '#7C7B5C',
  g: '#ABADA6',
  h: '#9F8624',
  i: '#746B45',
  j: '#312B25',
  k: '#8B6361',
  l: '#74788E',
};

export const useScrollToTop = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0 });
    // scroll to the top of the browser window when changing route
    // the window object is a normal DOM object and is safe to use in React.
  }, [location]);
};

export const directions = {
  N: 'N',
  S: 'S',
  E: 'E',
  W: 'W',
  SW: 'SW',
  SE: 'SE',
  NW: 'NW',
  NE: 'NE',
};

export const formatDate = (dateString) => {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  // Parse the date string (YYYY-MM-DD) into a Date object
  const date = new Date(dateString);

  // Ensure date is treated as UTC
  const utcDay = days[date.getUTCDay()];
  const utcMonth = months[date.getUTCMonth()];
  const utcDate = date.getUTCDate();
  const utcYear = date.getUTCFullYear();

  // Format the date as required
  return `${utcDay} ${utcMonth} ${String(utcDate).padStart(2, '0')} ${utcYear}`;
};
