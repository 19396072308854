import React, { useEffect, useState } from 'react';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  ZoomControl,
  LayersControl,
  useMap,
} from 'react-leaflet';
import { BsFullscreen, BsFullscreenExit } from 'react-icons/bs';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import DOMPurify from 'dompurify'; // Import DOMPurify for sanitizing HTML content
// import { formatDate } from '../utils/tools';
import customMarkerIcon from '../../assets/images/mapMarker.svg';
import './LeafletMap.css';

const { BaseLayer } = LayersControl;

const MapBounds = ({ points }) => {
  const map = useMap();
  useEffect(() => {
    if (map && points.length > 0) {
      // Create a new bounds object
      const bounds = L.latLngBounds();
      // Loop through the points and extend the bounds
      points.forEach((point) => {
        const { latitude, longitude } = point;
        if (latitude && longitude) {
          bounds.extend([latitude, longitude]);
        }
      });
      // Fit the map view to the bounds
      map.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [map, points]);
  return null;
};

const FullscreenControl = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const map = useMap();

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      // Request fullscreen mode
      const mapElement = map.getContainer();
      if (mapElement.requestFullscreen) {
        mapElement.requestFullscreen();
      } else if (mapElement.webkitRequestFullscreen) {
        mapElement.webkitRequestFullscreen();
      } else if (mapElement.msRequestFullscreen) {
        mapElement.msRequestFullscreen();
      }
    } else {
      // Exit fullscreen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
    setIsFullscreen(!isFullscreen);
  };

  return (
    <div className="leaflet-control leaflet-bar">
      <div onClick={toggleFullscreen} className="fullscreen-control">
        {isFullscreen ? (
          <BsFullscreenExit size={20} />
        ) : (
          <BsFullscreen size={20} />
        )}
      </div>
    </div>
  );
};

const LeafletMap = ({ points }) => {
  const customIcon = new L.Icon({
    iconUrl: customMarkerIcon,
    iconSize: [20, 32], // Adjust the size as needed
  });

  return (
    <MapContainer
      center={[0, 0]}
      zoom={2}
      style={{ height: '500px', width: '100%' }}
      zoomControl={false}
    >
      <LayersControl position="bottomright">
        <BaseLayer checked name="OpenStreetMap">
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </BaseLayer>
        <BaseLayer name="Satellite">
          <TileLayer
            attribution='&copy; <a href="https://www.esri.com/en-us/home">Esri</a>'
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
          />
        </BaseLayer>
        <BaseLayer name="Topographic">
          <TileLayer
            attribution='&copy; <a href="https://www.esri.com/en-us/home">Esri</a>'
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
          />
        </BaseLayer>
      </LayersControl>
      {points.map((point) => {
        const { latitude, longitude, pointName, pointNumber, freeText, _id } =
          point;
        if (latitude && longitude) {
          return (
            <Marker
              key={_id}
              position={[latitude, longitude]}
              icon={customIcon}
            >
              <Popup>
                <div>
                  <h3
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        `${pointName} - ${pointNumber} (${point.date.substring(
                          0,
                          10
                        )})`
                      ),
                    }}
                  ></h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(freeText),
                    }}
                  ></p>
                </div>
              </Popup>
            </Marker>
          );
        }
        return null;
      })}
      <MapBounds points={points} />
      <ZoomControl position="topright" />
      <FullscreenControl />
    </MapContainer>
  );
};

export default LeafletMap;
