import { Routes, Route, Navigate } from 'react-router-dom';

import About from './components/About';
import Press from './components/Press';
import Sponsors from './components/Sponsors';
import TripSelector from './components/ExpeditionSelector';
import Trips from './components/Expeditions';
import EditTrips from './components/Private/EditTrips';
import Pictures from './components/Pictures';
import Galleries from './components/Galleries';
import PageNotFound from './components/PageNotFound';
import Forecast from './components/Forecast';
import { UserProvider } from './contexts/UserContext';
import Dev from './components/Dev';

const OhadRoutes = () => {
  return (
    <UserProvider>
      <Routes>
        <Route exact path="/" element={<About />} />
        <Route path="expeditions" element={<TripSelector />} />
        <Route path="expeditions/my/trip" element={<EditTrips />} />
        <Route path="expeditions/:tripId" element={<Trips />} />
        <Route path="/pictures" element={<Galleries />} />
        <Route path="/pictures/:galleryId" element={<Pictures />} />
        <Route path="/dev" element={<Dev />} />
        <Route path="/forecast" element={<Forecast />} />
        <Route path="/press" element={<Press />} />
        <Route path="/sponsors" element={<Sponsors />} />
        {/* Redirect all non-existing routes to 404 page */}
        <Route path="*" element={<Navigate to="/404" />} />

        {/* 404 page */}
        <Route path="/404" element={<PageNotFound />} />
      </Routes>
      {/**  create margin in the bottom of the page:    */}
      <div style={{ marginBottom: '4rem' }} />
    </UserProvider>
  );
};

export default OhadRoutes;
