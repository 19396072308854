import { useEffect, useState } from 'react';
import LightGallery from 'lightgallery/react';
import TheSpinner from '../utils/TheSpinner';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

// import { LightGallerySettings } from 'lightgallery/lg-settings';
import { isMobile } from 'react-device-detect';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

const TheGallery = ({ images }) => {
  const getWidth = () => {
    if (isMobile) {
      return '98%';
    }
    return '250px';
  };

  const getHeight = () => {
    if (isMobile) {
      return '';
    }
    return '300px';
  };

  const [loading, setLoading] = useState(true);
  const [caruselImages, setCaruselImages] = useState([]);

  useEffect(() => {
    if (images === null) {
      setLoading(true);
    } else if (images && images.length) {
      const all = images.map((pic) => {
        return (
          <a
            href={pic.original}
            className="gallery-item"
            key={`p_${pic.picNumber}`}
            data-sub-html={`<div className='lightGallery-captions'><h4>${pic.commentTitle}</h4><p>${pic.comment}</p></div>`}
            style={{}}
          >
            <img
              className="img-responsive"
              style={{
                maxWidth: getWidth(),
                maxHeight: getHeight(),
                margin: '5px',
              }}
              alt={pic.commentTitle}
              src={pic.original}
            />
          </a>
        );
      });
      setCaruselImages(all);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [images, loading]);

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '2rem' }}>
        <TheSpinner />
      </div>
    );
  } else if (images && images.length === 0 && !loading) {
    return <h2>No pictures for this trip yet</h2>;
  }

  return (
    <>
      <LightGallery
        mode="lg-fade"
        speed={500}
        isMobile={() => isMobile}
        plugins={[lgThumbnail, lgZoom]}
        allowMediaOverlap={true}
        toggleThumb={true}
        style={{ border: 'solid black 2px' }}
      >
        {caruselImages}
      </LightGallery>
      <p></p>
    </>
  );
};

export default TheGallery;
