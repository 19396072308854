import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { getPictures } from '../api/tripsApi';
import TheGallery from './gallery/TheGallery';
import { useScrollToTop } from './utils/tools';

const Pictures = () => {
  const { galleryId } = useParams();
  const [pics, setPictures] = useState(null);
  const [title, setTitle] = useState('');
  const location = useLocation();

  const year = location?.state?.year;
  const comment = location?.state?.comment;

  useEffect(() => {
    const LS_KEY = `${process.env.REACT_APP_LOCAL_STORAGE_PREFIX}_galleryId_${galleryId}`;
    const getAllPictures = async () => {
      let data = localStorage.getItem(LS_KEY);
      let galleryImages = {};
      if (
        !data ||
        data.length === 0 ||
        data === '[]' ||
        process.env.REACT_APP_RELOAD_DATA === 'yes'
      ) {
        const results = await getPictures({ galleryId });
        if (!results) {
          return null;
        }

        const pictures = results.pictures.map((pic) => {
          return {
            id: pic._id,
            _id: pic._id,
            galleryId: pic.galleryId,
            picNumber: pic.picNumber,
            commentTitle: pic.commentTitle,
            comment: pic.comment,
            original: `${process.env.REACT_APP_GCP_URL}/${pic.image}`,
            thumbnail: `${process.env.REACT_APP_GCP_URL}/${pic.image}`,
            fullscreen: true,
            originalAlt: pic.comment,
            // originalTitle: pic.commentTitle,
            // description: pic.comment,
            description: pic.commentTitle,
          };
        });

        galleryImages = { pictures, title: results.title };
        localStorage.setItem(LS_KEY, JSON.stringify(galleryImages));
      } else {
        galleryImages = JSON.parse(data);
      }

      setPictures(galleryImages.pictures);
      setTitle(galleryImages.title);
    };
    getAllPictures();
  }, [galleryId]);

  useScrollToTop();
  return (
    <>
      <h2>
        {title} - {year}
      </h2>
      <h4
        style={{
          fontSize: '1.4rem',
        }}
      >
        {comment}
      </h4>
      <TheGallery images={pics} />
    </>
  );
};
export default Pictures;
