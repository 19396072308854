import { Image } from 'react-bootstrap';
const PDFViewer = ({ fileInfo }) => {
  if (!fileInfo.file) return <></>;

  const file = `${process.env.REACT_APP_GCP_SITE_PDFS_URL}/${fileInfo.file}`;
  if (fileInfo.type === 'pdf') {
    return (
      <div>
        <iframe src={file} width="100%" height="800px" title="pdf" />
      </div>
    );
  }
  if (fileInfo.type === 'image') {
    return <Image src={file} alt={fileInfo.title} width="100%" />;
  }
};

export default PDFViewer;
