import { useEffect, useState } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
// import classNames from 'classnames';
import { getPoints } from '../api/tripsApi';
import MapPoints from './map/MapPoints';
import TheSpinner from './utils/TheSpinner';
import LeafletMap from './map/LeafletMap';
import classes from './Expeditions.module.css';

const Expeditions = () => {
  const { tripId } = useParams();
  const [points, setPoints] = useState([]);
  const [pageTitle, setPageTitle] = useState('');

  const location = useLocation();
  const PREFIX = process.env.REACT_APP_LOCAL_STORAGE_PREFIX;
  // const className={classNames('btn btn-secondary btn-lg btn-block', classes.PicButton)}
  const className = 'btn btn-secondary btn-lg btn-block ' + classes.PicButton;

  useEffect(() => {
    const fetchPoints = async (trip) => {
      const LS_KEY = `${PREFIX}_tripId_${trip}`;
      let data = JSON.parse(localStorage.getItem(LS_KEY));

      // Fetch data if not in local storage or if the trip status isn't 'done'
      if (
        !data ||
        data.length === 0 ||
        process.env.REACT_APP_RELOAD_DATA === 'yes' ||
        location?.state?.status !== 'done'
      ) {
        try {
          data = await getPoints({ tripId: trip });
          if (data && data.length > 0) {
            localStorage.setItem(LS_KEY, JSON.stringify(data));
            setPoints(data);
          } else {
            console.error('No points data found');
            // Handle error case
          }
        } catch (error) {
          console.error('Error fetching points:', error);
        }
      } else {
        setPoints(data);
      }
    };

    fetchPoints(tripId);

    // Set page title from local storage trips data
    const trips = localStorage.getItem(`${PREFIX}_trips`);
    if (trips) {
      const tripsObj = JSON.parse(trips);
      const trip = tripsObj.find(
        (t) => t.tripId.toLocaleUpperCase() === tripId.toLocaleUpperCase()
      );
      setPageTitle(trip?.tripName || 'Trip');
    }
  }, [tripId, location?.state?.status, PREFIX]);

  const MapPage = ({ points }) => (
    <>
      <LeafletMap points={points} />
      <Link
        to={`/pictures/${tripId === 'faroe' ? 'faroes' : tripId}`}
        // className="btn btn-secondary btn-lg btn-block"
        className={className}
      >
        <span style={{ textTransform: 'capitalize' }}>
          {pageTitle} Pictures
        </span>
      </Link>

      <MapPoints
        points={points}
        style={{
          // animate transitioned loading
          transition: 'opacity 5s ease-in-out',
          opacity: points.length > 0 ? 1 : 0,
        }}
      />
    </>
  );

  return (
    <>
      <h2>{pageTitle}</h2>
      {tripId && points.length > 0 ? (
        <MapPage points={points} />
      ) : (
        <div style={{ textAlign: 'center', marginTop: '15px' }}>
          <TheSpinner size="sm" />
        </div>
      )}
    </>
  );
};

export default Expeditions;
