import { useEffect, useState } from 'react';
import Point from './Point';

const MapPoints = ({ points }) => {
  const [tripStory, setTripStory] = useState([]);
  useEffect(() => {
    const pointsParegraphs = () => {
      const all = points.map((p) => {
        return <Point key={p._id} point={p} />;
      });
      setTripStory(all);
    };

    pointsParegraphs();
    return () => setTripStory([]);
  }, [points]);
  // const Point = (p) => {
  //   return <div key={p._id}></div>;
  // };

  return points ? <>{tripStory}</> : <></>;
};

export default MapPoints;
