import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';

import { useScrollToTop } from './utils/tools';
import PDFViewer from './PDFViewer';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url
// ).toString();

const pressData = [
  {
    file: 'tassie_paddles_magazine.pdf',
    title: 'Tassmania - Paddles Magazine',
    image: 'tassie_1.png',
    type: 'pdf',
  },
  {
    file: 'tassie_2.pdf',
    title: 'Tasmania',
    image: 'tassie_2.png',
    type: 'pdf',
  },
  {
    file: 'svalbard_1.pdf',
    title: 'Svalbard',
    image: 'svalbard_1.png',
    type: 'pdf',
  },
  {
    file: 'ireland_1.pdf',
    title: 'Ireland',
    image: 'ireland_1.png',
    type: 'pdf',
  },
  {
    file: 'stewart_island.png',
    title: 'Stewart Island',
    image: 'stewart_island.png',
    type: 'image',
  },
];

const Press = () => {
  useScrollToTop();
  // const [file, setFile] = useState(null);
  const [currentPressInfo, setCurrentPressInfo] = useState(null);

  return (
    <Col>
      <h2>{currentPressInfo?.title || 'Press'}</h2>
      <Row style={{ marginBottom: '1rem' }}>
        {pressData.map((press) => (
          <Col
            key={`press_${press.title}`}
            xs={12}
            sm={6}
            md={3}
            lg={2}
            style={{ marginBottom: '1rem', cursor: 'pointer' }}
            onClick={() => setCurrentPressInfo(press)}
          >
            <Image
              src={`${process.env.REACT_APP_GCP_SITE_PDFS_URL}/${press.image}`}
              alt={press.title}
              fluid
            />
          </Col>
        ))}
      </Row>
      {currentPressInfo && <PDFViewer fileInfo={currentPressInfo} />}
    </Col>
  );
};

export default Press;
