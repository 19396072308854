import { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { getForecast } from '../api/forecastApi';
import Button from 'react-bootstrap/Button';
// import classes from './Forecast.module.css';

const Galleries = () => {
  const [todayForecast, setTodayForecast] = useState(null);
  const [thisWeekForecast, setThisWeekForecast] = useState(null);
  const [today, setToday] = useState(true);
  const [title, setTitle] = useState('');

  useEffect(() => {
    if (today && todayForecast) {
      setToday(true);
    } else if (!today && thisWeekForecast) {
      setToday(false);
    }
  }, [today, todayForecast, thisWeekForecast]);

  const getForecastByPoint = async (point) => {
    try {
      const data = await getForecast(point);
      setTitle(point === 'sdot_yam' ? 'Sdot Yam' : 'Tel Aviv - Hayarkon');
      if (!data) {
        // TODO set error
        return null;
      }
      const { weekForecast, oneDayForecast } = data;
      setThisWeekForecast(
        setData({
          dateLabel: weekForecast.dateLabel,
          timeLabel: weekForecast.timeLabel,
          dayOnly: weekForecast.dayOnly,
          waveHeight: weekForecast.wave_height,
          windWaveHeight: weekForecast.wind_wave_height,
          startDate: weekForecast.localTime[0],
        })
      );
      setTodayForecast(
        setData({
          dateLabel: oneDayForecast.oneDayDateLabels,
          timeLabel: oneDayForecast.oneDayTimeLables,
          dayOnly: oneDayForecast.oneDayDayOnly,
          waveHeight: oneDayForecast.oneDayWaveHeight,
          windWaveHeight: oneDayForecast.oneDayWindWaveHeight,
          startDate: oneDayForecast.oneDayLocalTime[0],
        })
      );
    } catch (error) {
      // TODO set error
      console.log(error);
    }
  };

  const setData = ({
    dateLabel,
    timeLabel,
    dayOnly,
    waveHeight,
    windWaveHeight,

    startDate,
    interval,
  }) => {
    const options = {
      title: 'Wave Height Forecast',
      type: 'line',
      xAxis: {
        title: 'Time',
        categories: dateLabel,
        type: 'datetime',
      },
      yAxis: {
        title: 'Wave Height (m)',
      },
      series: [
        {
          name: 'Wave Height',
          data: waveHeight,
        },
        {
          name: 'Wind Wave Height',
          data: windWaveHeight,
        },
      ],
    };
    return options;
  };

  return (
    <>
      <h2>Forecast</h2>
      <div>
        <div style={{ textAlign: 'center' }}>
          <h2>{title ? title : 'No location yet'}</h2>
        </div>
        <Button
          variant="light"
          title="Sdot Yam"
          onClick={() => getForecastByPoint('sdot_yam')}
          style={{ margin: '5px' }}
        >
          Sdot Yam
        </Button>
        <Button
          variant="light"
          onClick={() => getForecastByPoint('tlv_yarkon')}
          title="Tel Aviv - Hayarkon"
        >
          Tel Aviv - Hayarkon
        </Button>
        <Button
          variant="light"
          onClick={() => setToday(!today)}
          title={!today ? 'Set One Day Forecast' : 'Set Week Forecast'}
          style={{ margin: '5px', float: 'right' }}
        >
          {!today ? 'Set One Day Forecast' : 'Set Week Forecast'}
        </Button>
      </div>
      {todayForecast && (
        <HighchartsReact
          highcharts={Highcharts}
          options={today ? todayForecast : thisWeekForecast}
        />
      )}
    </>
  );
};

export default Galleries;
