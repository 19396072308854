export const handleError = (error) => {
  if (error.response) {
    console.warn('error.response', error.response);
    if (error?.response?.status) {
      switch (error.response.status) {
        case 401: // not Authorized
        case 403:
          localStorage.removeItem('user');
          return false;
        case 404:
        default:
          if (error.response.data) {
            return error.response.data;
          } else if (error.request) {
            return {
              status: 'FAILED',
              error: 'There was no response...',
            };
          } else {
            return {
              status: 'FAILED',
              error: error.message,
            };
          }
      }
    }
  }
};
