import React, { useState, useEffect } from 'react';
import {
  degToDms,
  htmlParser,
  breakLines,
  getDegreesDirection,
  getWindDegreesDirection,
} from '../utils/tools';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import { MdDateRange, MdAnchor, MdOutlineLocationOn } from 'react-icons/md';
import { FaArrowUpLong, FaRoute } from 'react-icons/fa6';
import { BsWind } from 'react-icons/bs';
import { formatDate } from '../utils/tools';
import classes from './Point.module.css';

const NM_TO_KM = 1.852;

const Point = ({ point }) => {
  const [showModal, setShowModal] = useState(false);
  const [width, setWidth] = useState('100%');

  useEffect(() => {
    const updateWidth = () => {
      if (window.innerWidth > 768) {
        // Adjust the width breakpoint as needed
        setWidth('50%');
      } else {
        setWidth('100%');
      }
    };

    window.addEventListener('resize', updateWidth);

    // Set initial width
    updateWidth();

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const handleImageClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const getLoc = (latitude, longitude) => {
    if (longitude && latitude) {
      return `${degToDms(point.latitude, false)};${degToDms(
        point.longitude,
        true
      )}`;
    }

    return '';
  };

  const PointDirection = ({ direction, wind }) => {
    if (direction) {
      let degrees = getDegreesDirection(direction);
      if (wind) {
        degrees = getWindDegreesDirection(direction);
      }
      return (
        <span>
          <FaArrowUpLong style={{ rotate: `${degrees}deg` }} />
        </span>
      );
    }
    return '';
  };

  return point ? (
    <div
      key={point._id}
      style={{
        marginTop: '15px',
        fontSize: '1.1rem',
      }}
    >
      <Container fluid className="p-0">
        <Row className={classes.PointHeader}>
          <Col xs={12} md={6} lg={4}>
            <MdAnchor /> {htmlParser(point.pointName)}
          </Col>
          <Col xs={12} md={6} lg={4}>
            <MdDateRange /> {formatDate(point.date)}
          </Col>
          {point.latitude ? (
            <Col xs={12} md={6} lg={4}>
              <MdOutlineLocationOn /> {getLoc(point.latitude, point.longitude)}
            </Col>
          ) : (
            ''
          )}
          {point.distanceNoticalMiles ? (
            <Col xs={12} md={6} lg={4}>
              <FaRoute /> {`${point.distanceNoticalMiles} nm `}{' '}
              {`(${(point.distanceNoticalMiles * NM_TO_KM).toFixed(1)} km) `}
              <PointDirection direction={point.paddleDirection} wind={false} />
            </Col>
          ) : (
            ''
          )}
          {point.windStrength ? (
            <Col xs={12} md={6} lg={4}>
              <BsWind /> {`${point.windStrength} knots`}{' '}
              <PointDirection wind direction={point.windDirection} />
            </Col>
          ) : (
            ''
          )}
        </Row>
        <Row>
          <Col
            style={{ marginLeft: '1rem', paddingTop: '1rem' }}
            dangerouslySetInnerHTML={{ __html: breakLines(point.freeText) }}
          ></Col>
        </Row>
        {point?.image && (
          <Row>
            <Col
              style={{
                padding: '1rem',
              }}
              className="text-center"
            >
              <Image
                src={point.image}
                style={{ width, cursor: 'pointer' }}
                onClick={handleImageClick}
              />
            </Col>
          </Row>
        )}
      </Container>

      {/* Modal for image enlargement */}
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Body style={{ padding: 0, textAlign: 'center', width: '100%' }}>
          <Image src={point.image} fluid style={{ maxWidth: '100%' }} />
        </Modal.Body>
      </Modal>
    </div>
  ) : (
    ''
  );
};

export default Point;
