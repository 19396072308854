import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import { getGalleries } from '../api/tripsApi';
import TheSpinner from './utils/TheSpinner';
import classes from './CardTitle.module.css';
import { MdOutlineKayaking } from 'react-icons/md';

const Galleries = () => {
  const [galleries, setGalleries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getAllGalleries = async () => {
      const LS_KEY = `${process.env.REACT_APP_LOCAL_STORAGE_PREFIX}_galleries`;
      let data = JSON.parse(localStorage.getItem(LS_KEY));
      if (
        !data ||
        data.length === 0 ||
        process.env.REACT_APP_RELOAD_DATA === 'yes'
      ) {
        try {
          data = await getGalleries();
          if (!data || data.length === 0) {
            // TODO: Set error
            return;
          }
          // data = JSON.stringify(data);
          localStorage.setItem(LS_KEY, JSON.stringify(data));
        } catch (error) {
          // TODO: Handle error
          return;
        }
      }
      setGalleries(data);
      setIsLoading(false);
    };

    getAllGalleries();
  }, []);

  const GalleriesCards = ({ galleriesList }) =>
    galleriesList.map((gallery) => (
      <Col xs={12} sm={6} md={4} key={gallery.galleryId}>
        <div className={classes.CardTitle}>
          <div className={classes.Title}>{gallery.title}</div>
        </div>
        <Card border="light" style={{ marginBottom: '1rem' }}>
          <Link
            to={`${gallery.galleryId.toLowerCase()}`}
            state={{
              year: gallery.year,
              comment: gallery.comment,
            }}
          >
            <Card.Img
              variant="top"
              src={
                gallery?.thumbnail ? (
                  `${process.env.REACT_APP_GCP_URL}/galleries/${gallery.thumbnail}`
                ) : (
                  <MdOutlineKayaking />
                )
              }
            />
            {/* <Card.ImgOverlay>
              <Card.Title className={classes.CardTitle}>
                <div className={classes.Title}>{gallery.title}</div>
              </Card.Title>
            </Card.ImgOverlay> */}
          </Link>
        </Card>
      </Col>
    ));

  return (
    <>
      <h2>Pictures</h2>
      {isLoading ? (
        <div style={{ textAlign: 'center', marginTop: '2rem' }}>
          <TheSpinner />
        </div>
      ) : galleries.length > 0 ? (
        <GalleriesCards galleriesList={galleries} />
      ) : (
        <div>No galleries available.</div>
      )}
    </>
  );
};

export default Galleries;
