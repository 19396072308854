import { BrowserRouter } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Analytics } from '@vercel/analytics/react';
import Navigationbar from './components/navigation/Navigationbar';
import OhadRoutes from './Routes';
import Footer from './components/Footer';
import { useGoogleAnalytics } from './hooks/useGoogleAnalytics';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const App = () => {
  useGoogleAnalytics();
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <div className="App">
        <BrowserRouter>
          <Navigationbar />
          <div className="app-container">
            <Container fluid>
              <Row className="justify-content-center">
                <OhadRoutes />
              </Row>
            </Container>
          </div>
          <Footer />
        </BrowserRouter>
        <Analytics />
      </div>
    </GoogleOAuthProvider>
  );
};

export default App;
