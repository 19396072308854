import { api } from './Api';
import { handleError } from './apiUtils';

let opt = {
  headers: { 'Content-Type': 'application/json' },
};
export const getTrips = async () => {
  try {
    const response = await api.get('/trips', opt);
    if (undefined === response) return false;

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const getPoints = async (payload) => {
  try {
    const response = await api.get(`/trips/${payload.tripId}`, opt);
    if (undefined === response) return false;
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const getGalleries = async () => {
  try {
    const response = await api.get('/galleries', opt);
    if (undefined === response) return false;

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const getPictures = async (payload) => {
  try {
    const response = await api.get(`/galleries/${payload.galleryId}`, opt);
    if (undefined === response) return false;
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const savePoint = async (payload, pointId) => {
  const accessToken = localStorage.getItem('accessToken');
  try {
    const data = {
      tripId: payload.tripId,
      pointNumber: Number(payload.pointNumber),
      pointName: payload.pointName,
      longitude: Number(payload.longitude),
      latitude: Number(payload.latitude),
      paddleDirection: payload.paddleDirection,
      distanceNoticalMiles: Number(payload.distanceNoticalMiles),
      date: payload.date,
      windDirection: payload.windDirection,
      windStrength: Number(payload.windStrength),
      freeText: payload.freeText,
      image: payload.image,
      // password: payload.password,
    };

    const authHeader = `Bearer ${accessToken}`;
    let response = null;
    if (pointId) {
      console.log('updating point', pointId);
      response = await api.put(`/point/${pointId}`, data, {
        headers: {
          Authorization: authHeader,
          // 'Content-Type': 'text/plain',
        },
      });
    } else {
      console.log('creating new point');
      response = await api.post(`/point`, data, {
        headers: { Authorization: authHeader },
      });
    }

    // if (response?.status !== 200) return false;
    console.log('response code', response?.status);

    return true;
  } catch (error) {
    console.error(error);
  }
};

export const isAllowed = async (payload) => {
  try {
    // console.log(payload);
    opt = {
      headers: { Authorization: `Bearer ${payload.token}` },
    };
    const response = await api.post(`/auth/verify`, {}, opt);
    console.log(response);
    if (undefined === response) return false;
    if (response.status !== 200) return false;
    if (
      response?.data?.message === 'Authorized' &&
      response?.data?.accessToken
    ) {
      localStorage.setItem('accessToken', response?.data?.accessToken);
      return true;
    }
    return false;
  } catch (error) {
    handleError(error);
  }
};
